export function useSortFormater () {
  function sortString<T>(sortParam: keyof T, items: T[]): T[] {
    const compareValues: any = (a: any, b: any) => {
      const valueA: any = a[String(sortParam).replace('-','')];
      const valueB: any = b[String(sortParam).replace('-', '')];

      if (typeof valueA === 'string' && typeof valueB === 'string') {
        return valueA.localeCompare(valueB);
      }

      if (typeof valueA === 'number' && typeof valueB === 'number') {
        return valueA - valueB;
      }

      return ''; // (если будем сравнивать не совпадающие типы)
    }

    const sortedItems: any = [...items].sort((a: any, b: any) => {
      if (String(sortParam)?.[0] === '-') {
        return compareValues(b, a); // Обратная сортировка, по ручному параметру '-'
      }
      return compareValues(a, b);
    });

    console.log('sortedItems', sortedItems)
    return sortedItems;
  }

  return {
    sortString
  };
}
